.headerContainer {
  color: #fff;
  background: #231f20;
  display: flex;
  padding: 10px 5px;
  height: fit-content;
  justify-content: space-between;
}

.headerContainer img {
  width: fit-content;
  margin: 5px 10px;
}

.Navigate {
  cursor: pointer;
}
