.Input_Label {
  font-size: 1.3rem;
}

.Input {
  font-size: 1.1rem;
  border: 1px solid #58595b;
  min-width: 15rem;
  color: #000;
  padding: 3px 5px;
}

.Input:focus {
  border-color: var(--main-black);
}

.Input_Textarea {
  resize: none;
  font-size: 1.1rem;
  color: #000;
}

.Input_Required {
  color: red;
  font-size: 1.2rem;
}

.ErrorDiv {
  min-height: 1.2rem;
  width: 15rem;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}

.ErrorDes {
  color: #ff0000;
  font-size: 0.9rem;
  font-weight: bold;
}

.Input_File {
  display: flex;
  align-items: baseline;
}

.Input_File_Container {
  margin-left: 1rem;
}

.File_Input {
  display: none;
}

.File_Label {
  cursor: pointer;
  padding: 0.1rem 0.5rem;
  text-align: center;
  border: 1px #000 solid;
  max-width: 15rem;
  height: 1.9rem;
  max-height: 2rem;
  border-radius: 5px;
  overflow: hidden;
}

.File_Label:hover {
  box-shadow: inset -0.5px -1px 3px 1px #6e6e6e;
}
