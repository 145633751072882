.Home {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Overlay_Description {
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.4px;
  margin: 40px;
}

.Logo {
  height: 8rem;
  width: 8rem;
}

.Form_Button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transForm 80ms ease-in;
  margin-top: 30px;
}

.Form_Button:active {
  transform: scale(0.95);
}

.Form_Button:focus {
  outline: none;
}

.Form_Button.Ghost {
  background-color: transparent;
  cursor: pointer;
  border-color: #ffffff;
}

.Form_Button.Ghost:focus,
.Form_Button.Ghost:hover {
  box-shadow: inset -1px -3px 8px #aaa, 2px 2px 8px black;
}

.Form {
  background-color: #fefefe;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  color: #231f20;
}

.Form_Title {
  margin-bottom: 30px;
}

.Container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 70rem;
  height: 35rem;
  max-width: 100%;
  max-height: 35rem;
}

.Form_Container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.Admin_Container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.Faculty_Container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.Container.Right_Panel_Active .Admin_Container {
  transform: translateX(100%);
}

.Container.Right_Panel_Active .Faculty_Container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.Overlay_Container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transForm 0.6s ease-in-out;
  z-index: 100;
}

.Container.Right_Panel_Active .Overlay_Container {
  transform: translateX(-100%);
}

.Overlay {
  background: var(--main-color);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transForm 0.6s ease-in-out;
}

.Container.Right_Panel_Active .Overlay {
  transform: translateX(50%);
}

.Overlay_Panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transForm 0.6s ease-in-out;
}

.Overlay_Left {
  transform: translateX(-20%);
  padding: 0;
}

.Container.Right_Panel_Active .Overlay_Left {
  transform: translateX(0);
}

.Overlay_Right {
  padding: 0;
  right: 0;
  transform: translateX(0);
}

.Container.Right_Panel_Active .Overlay_Right {
  transform: translateX(20%);
}
