.footerContainer {
  color: #fff;
  position: relative;
  background: #231f20;
  display: flex;
  padding: 1rem 0.5rem;
  height: fit-content;
  justify-content: space-around;
}

.footerContainer a {
  color: #fff;
  text-decoration: none;
}
