:root {
  --main-color: #b7202e;
  --main-red: #ed1c24;
  --main-gray: #58595b;
  --main-white: #ffffff;
  --main-black: #231f20;
  --sec-orange: #f58200;
  --sec-yellow: #ffcb05;
  --sec-brown: #603312;
  --sec-chocolate: #a25723;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: Georgia, "Times New Roman", Times, serif;
}

html {
  height: 100%;
}

body {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

.App {
  height: 100%;
}
