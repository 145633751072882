.Login_Title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.Login_Error {
  text-align: center;
  color: #f00;
  font-size: 1.2rem;
  height: 1.4rem;
}

.Login_Buttons {
  display: flex;
  justify-content: center;
}

.Login_Button {
  margin: 1rem 1rem;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
  font-weight: bold;
  outline: none;
}
.Login_Button:disabled {
  cursor: not-allowed;
}

.Login_Button:enabled {
  color: #84b71c;
  border-color: #84b71c;
}

.Login_Button:enabled:hover,
.Login_Button:enabled:focus {
  background-color: #84b71c;
  color: white;
  border-color: #ccc;
}

.Admin_Link {
  margin-top: 1rem;
  align-self: flex-end;
  margin-right: 3rem;
  font-size: 1.1rem;
  color: #2d6adb;
}

.Faculty_Links {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.Faculty_Link {
  font-size: 1.1rem;
  color: #2d6adb;
}

.Faculty_Link:hover,
.Admin_Link:hover {
  color: rgb(245, 154, 8);
}
